import './App.css';
import { Link, Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import Mandatory from './pages/Mandatory/Mandatory';
import About from './pages/About/About';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact';
import logo from '../src/utils/images/IqraaLogo.png';
import Document from './pages/Document/Document';
import { useState } from 'react';
import MyDocument from './components/pdfviewer/MyDocument';
import { SchoolContext } from './store/school-site-context';



function App() {

  const [isPDFMode,setisPDFMode] = useState(false);
  //const schoolCtx = useContext(SchoolContext);
  const [selectedPDF,setSelectedPDF] = useState("teaching staff.pdf");
  

  const docOpenHandler = ()=>{
     setisPDFMode(true);
  }

 
  
  let website = <div>
  <Navbar expand="lg" className='position-absolute w-100'>
    <Container>
      <Navbar.Brand>
        <Link to="/" className='navbar-brand d-flex align-items-center'>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-backpack-fill" viewBox="0 0 16 16">
            <path d="M5 13v-3h4v.5a.5.5 0 0 0 1 0V10h1v3z"/>
            <path d="M6 2v.341C3.67 3.165 2 5.388 2 8v5.5A2.5 2.5 0 0 0 4.5 16h7a2.5 2.5 0 0 0 2.5-2.5V8a6.002 6.002 0 0 0-4-5.659V2a2 2 0 1 0-4 0m2-1a1 1 0 0 1 1 1v.083a6.04 6.04 0 0 0-2 0V2a1 1 0 0 1 1-1m0 3a4 4 0 0 1 3.96 3.43.5.5 0 1 1-.99.14 3 3 0 0 0-5.94 0 .5.5 0 1 1-.99-.14A4 4 0 0 1 8 4M4.5 9h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5"/>
          </svg> */}
          <img src={logo} alt="iqraalogo" style={{height: 80, width: 95}}/>
          <span className='mx-2 text-light lh-1 fw-semibold'>
            IQRAA
            <br></br>
            PUBLIC
            <br></br>
            SCHOOL
          </span>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' className='bg-light' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='me-auto justify-content-end w-100'>
          <Nav.Link href='/' className='text-uppercase'>Home</Nav.Link>
          <Nav.Link href='/about' className='text-uppercase'>About us</Nav.Link>
          <Nav.Link href='/courses' className='text-uppercase'>Academic</Nav.Link>
          <Nav.Link href='/gallery' className='text-uppercase'>Gallery</Nav.Link>
          <Nav.Link href='/mandatory' className='text-uppercase'>Mandatory</Nav.Link>
          <Nav.Link href='/contact' className='text-uppercase'>Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/courses' element={<Courses />} />
    <Route path='/about' element={<About />} />
    <Route path='/gallery' element={<Gallery />} />
    <Route path='/mandatory' element={<Mandatory setPdf={setSelectedPDF} />} />
    <Route path='/contact' element={<Contact />} />

    <Route path='/affiltation.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/trust.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/noc.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/recognition.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/building.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/firecertificate.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/selfcertificate.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/waterhealth.pdf' element={<Document docHandler={docOpenHandler}/>} />

    <Route path='/fee.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/academic.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/smc.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/pta.pdf' element={<Document docHandler={docOpenHandler}/>} />
    <Route path='/result.pdf' element={<Document docHandler={docOpenHandler}/>} />
  </Routes>

  <footer>
    <div className='container my-5'>
      <div className='row d-flex justify-content-between align-items-center'>
        <div className='col-md-4'>
          <Link to="/contact">
            <button type='button' className='btn btn-outline-success btn-lg mb-5 mb-md-4'>Get In Touch</button>
          </Link>
          <ul className='footer-social-icons list-unstyled d-flex justify-content-between'>
            <li>
              <a href='https://www.instagram.com/iqraapublicschool/'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#B83985FF" class="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                </svg>
              </a>
              </li>
              <li>
              <a href='https://www.facebook.com/profile.php?id=61564184425246'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#3144A2FF" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                </svg>
                </a>
              </li>
              <li>
              <a href='https://www.youtube.com/watch?v=KwOQrogq12U6'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#dc3545" class="bi bi-youtube" viewBox="0 0 16 16">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
                </svg>
                </a>
              </li>
          </ul>
        </div>
        
        <div className='col-md-7 col-lg-6'>
          <div className='d-row d-md-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-6 col-lg-5 mb-5 mt-4 my-md-0'>
              <ul className='footer-navigation list-unstyled mb-0'>
                <Link to="/" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>Home</li>
                </Link>
                <Link to="/about" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>About us</li>
                </Link>
                <Link to="/courses" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>Academic</li>
                </Link>
                <Link to="/gallery" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>Gallery</li>
                </Link>
                <Link to="/mandatory" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>Mandatory</li>
                </Link>
                <Link to="/contact" className='text-decoration-none text-success'>
                  <li className='text-uppercase fw-semibold'>Contact Us</li>
                </Link>
              </ul>
            </div>
            <div className='col-12 col-md-6 col-lg-7'>
              <ul className='list-unstyled mb-0'>
                <li>
                  <p>V & P.O. Fatehpur Taga, Distt- Faridabad, 121004, Haryana, India</p>
                </li>
                <li>
                  <p>Phone Number - +917303015715</p>
                </li>
                <li>
                  <p>Email - iqraaschoolofficial@gmail.com</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='bg-body-tertiary'>
      <div className='container'>
        <p className='p-3 m-0 text-center'> </p>
      </div>
    </div>
  </footer>
</div>
;

  if(isPDFMode){
    website = <MyDocument selected={selectedPDF}/>
  }
  
  return (
    <SchoolContext.Provider value={{ selected: [selectedPDF]}}>
    {website}
    </SchoolContext.Provider>
  );

  
}

export default App;
